import React from 'react'

const About: React.FC = () => {
  return (
    <div>
      <div className="uk-child-width-1-2" data-uk-grid>
        <h1>Lisa all in all is a pretty awesome woman</h1>
      </div>
    </div>
  )
}

export default About
