import * as React from 'react'

import About from '../components/About'
import LayoutRoot from '../components/LayoutRoot'

const AboutPage = () => (
  <LayoutRoot>
    <About />
  </LayoutRoot>
)

export default AboutPage
